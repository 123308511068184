<template>
	<div style="padding: var(--page-padding); height: var(--content-height)">
		<elt-title :size="24" icon="icon-window" title="壁纸 & 插画"></elt-title>
		<elt-navi :nav="this.navBar" @switch="switchTab" />

		<div class="content" style="margin-top: 20px; height: calc(var(--content-height) - var(--title-height) - var(--navi-height) - 20px);">

			<div v-show="current == 0">
				<div style="display: grid; grid-template-columns: repeat(8, calc( calc(100% - 112px) / 8 ) ); grid-gap: 16px; width: 100%;">
					<div v-for="item in mobileList" style="width: 100%; " class="flex">
						<img :src="'https://cdn.jsdelivr.net/gh/vhhgx/common@master/images/wallpaper/mobile/'+`${item.name}`" style="width: 100%; ">
					</div>
				</div>
			</div>

			<div v-show="current == 1">
				<div style="display: grid; grid-template-columns: repeat(5, calc( calc(100% - 64px) / 5 ) ); grid-gap: 16px; width: 100%; ">
					<div v-for="item in pcList" style="width: 100%; " class="flex">
						<img :src="'https://cdn.jsdelivr.net/gh/vhhgx/common@master/images/wallpaper/pc/'+`${item.name}`" style="width: 100%; height: 171px;">
					</div>
				</div>
			</div>
			<div v-show="current == 2">
				<div style="display: grid; grid-template-columns: repeat(5, calc( calc(100% - 64px) / 5 ) ); grid-gap: 16px; width: 100%; ">
					<div v-for="item in illustList" style="width: 100%;" class="flex">
						<img :src="'https://cdn.jsdelivr.net/gh/vhhgx/common@master/images/illustration/'+`${item.name}`" style="width: 100%; height: 203px;">
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	data() {
		return {
			mobileList: [],
			pcList: [],
			illustList: [],
			current: 0,
			navBar: ["手机壁纸", "桌面壁纸", "插画"]
		}
	},
	components: { },
	mounted() {
		this.getList('/images/wallpaper/mobile')
		this.getList('/images/wallpaper/pc')
		this.getList('/images/illustration')
	},
	methods: {
		getList(path) {
			axios.post('https://api.vhhg.site/list', {path: path}).then(res => {
				console.log('res', res.data)

				if( path == '/images/wallpaper/mobile' ) {
					this.mobileList = res.data
				} else if ( path == '/images/wallpaper/pc') {
					this.pcList = res.data
				} else {
					let ill = []
					res.data.forEach(item => {
						if (item.isFolder == false) ill.push(item)
					})
					this.illustList = ill
				}
			})
		},

		switchTab(idx) { this.current = idx },
	}
}
</script>

<style>

</style>